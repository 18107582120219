@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

:root {
  --primary: #08193e;
  --primaryD1: #061432;
  --primaryL1: #394765;
  --primaryL2: #616c84;
  --borderGray: #eeeeee;
  --systemRed: #d32f2f;
  --systemYellow: #f9a825;
  --systemGreen: #4caf50;
  --systemBlue: #0091ea;
  --gray0: #ffffff;
  --gray1: #f5f5f5;
  --gray2: #eeeeee;
  --gray3: #e0e0e0;
  --gray4: #bdbdbd;
  --gray5: #9e9e9e;
  --gray6: #757575;
  --gray7: #616161;
  --gray8: #424242;
  --gray9: #000000;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none;
}

button,
input,
textarea,
select,
option {
  font: inherit;
  outline: none;
  box-sizing: border-box;
  color: black;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 16px;
  font-weight: 400;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Hegrayetica Neue", sans-serif;

  color: black;
}

body {
  line-height: 1.6;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border-left: solid 1px #dddfe0;
}
::-webkit-scrollbar-thumb {
  background: #dddfe0;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #dddfe0;
}
